import jsPDF from 'jspdf';
import moment from 'moment';

import createGeneralInformationTableForAuditLogs from './generalInformationTableForAuditLogs';
import createGeneralInformationTableForProtocols from './generalInformationTableForProtocols';
import { drawSeparationLine } from './helpers';
import * as style from './style';
import { GeneralInformationForAuditLogPDFTable, GeneralInformationForProtocolsPDFTable } from './types';

const getReportTitle = (bookName: string): string => {
  return `${bookName} Report`;
};

export const insertGeneralInformation = (
  doc: jsPDF,
  date: string | undefined,
  generalInfo: GeneralInformationForProtocolsPDFTable | GeneralInformationForAuditLogPDFTable,
  addVoyageToGeneralInfo: boolean,
  currentDateTime: moment.Moment,
  undefinedText: string,
  audit: boolean,
): { x: number; y: number } => {
  const generalInfoProtocol = generalInfo as GeneralInformationForProtocolsPDFTable;
  const generalInfoAudit = generalInfo as GeneralInformationForAuditLogPDFTable;
  doc.setFont(style.PAGE_TITLE_FONT_TYPE);
  doc.setFontSize(style.PAGE_TITLE_FONT_SIZE);
  doc.setTextColor(style.PAGE_TITLE_FONT_COLOR);
  doc.setProperties({
    title: audit ? 'Audit Log' : getReportTitle(generalInfoProtocol.bookName),
  });
  doc.text(
    audit ? 'Audit Log' : getReportTitle(generalInfoProtocol.bookName),
    style.DOCUMENT_WIDTH / 2,
    style.DOCUMENT_TITLE_Y,
    {
      align: 'center',
      baseline: 'middle',
    },
  );
  drawSeparationLine(doc, {
    y: style.SEPARATION_LINE_PAGE_TITLE_Y,
    color: style.SEPARATION_LINE_TOP_FIRST_PAGE_COLOR,
  });
  let cursorPosition: { x: number; y: number } = { x: 0, y: style.TABLE_INFORMATION_Y_START };
  if (audit) {
    cursorPosition = createGeneralInformationTableForAuditLogs(
      currentDateTime,
      date,
      cursorPosition,
      doc,
      generalInfoAudit,
      undefinedText,
    );
  } else {
    cursorPosition = createGeneralInformationTableForProtocols(
      currentDateTime,
      date,
      cursorPosition,
      doc,
      generalInfoProtocol,
      addVoyageToGeneralInfo,
      undefinedText,
    );
  }
  cursorPosition.y += style.TABLES_DATA_VERTICAL_DISTANCE;
  drawSeparationLine(doc, {
    y: cursorPosition.y,
    color: style.SEPARATION_LINE_COLOR,
  });
  return cursorPosition;
};
