import jsPDF from 'jspdf';
import autoTable, { applyPlugin } from 'jspdf-autotable';

import { dateTimeStr } from '#/utilities';

import { getAppliedFilter } from './helpers';
import * as style from './style';
import { GeneralInformationForProtocolsPDFTable } from './types';

applyPlugin(jsPDF);

export default function createGeneralInformationTableForProtocols(
  currentDateTime: moment.Moment,
  date: string | undefined,
  cursorPosition: { x: number; y: number },
  doc: jsPDF,
  generalInfo: GeneralInformationForProtocolsPDFTable,
  addVoyageToGeneralInfo: boolean,
  undefinedText: string,
): { x: number; y: number } {
  const leftValues = [
    [
      'Vessel:',
      generalInfo.vesselName
        ? { content: generalInfo.vesselName, styles: { font: style.TABLE_INFORMATION_VESSEL_FONT_TYPE } }
        : { content: undefinedText, styles: { font: style.UNDEFINED_FONT_TYPE } },
    ],
    [
      'Vessel IMO:',
      generalInfo.imo ? generalInfo.imo : { content: undefinedText, styles: { font: style.UNDEFINED_FONT_TYPE } },
    ],
  ];
  if (addVoyageToGeneralInfo) {
    leftValues.push(['Voyage:', generalInfo.voyage], ['From:', generalInfo.route.from], ['To:', generalInfo.route.to]);
  }
  const rightValues = [
    ['Exported by:', `${generalInfo.signingUser}`],
    ['Exported at:', dateTimeStr(currentDateTime.toISOString())],
  ];
  if (date) {
    rightValues.push(['Records of Date:', date]);
  }
  const combinedValues = [...Array(Math.max(leftValues.length, rightValues.length)).keys()].map((index) => {
    return [...(leftValues[index] || ['', '']), ...(rightValues[index] || ['', ''])];
  });
  autoTable(doc, {
    body: [...combinedValues, ['Applied Filters:', { content: getAppliedFilter(generalInfo), colSpan: 3 }]],
    columnStyles: {
      0: { cellWidth: style.TABLE_INFORMATION_LEFT_COLUMNS_WIDTH, cellPadding: { top: style.CELL_PADDING_TOP } },
      1: { cellWidth: style.TABLE_INFORMATION_RIGHT_COLUMNS_WIDTH, cellPadding: { top: style.CELL_PADDING_TOP } },
      2: {
        cellWidth: style.TABLE_INFORMATION_LEFT_COLUMNS_WIDTH + style.TABLES_INFORMATION_DISTANCE,
        cellPadding: { left: style.TABLES_INFORMATION_DISTANCE, top: style.CELL_PADDING_TOP },
      },
      3: { cellWidth: style.TABLE_INFORMATION_RIGHT_COLUMNS_WIDTH, cellPadding: { top: style.CELL_PADDING_TOP } },
    },
    margin: { left: style.CONTENT_MARGIN_LEFT },
    pageBreak: 'avoid',
    tableWidth: style.TABLE_INFORMATION_WIDTH,
    theme: 'plain',
    startY: cursorPosition.y,
    styles: {
      font: style.TABLE_INFORMATION_FONT_TYPE,
      textColor: style.TABLE_INFORMATION_FONT_COLOR,
      fontSize: style.TABLE_INFORMATION_FONT_SIZE,
    },
    didDrawCell: (data) => {
      cursorPosition = data.cursor as { x: number; y: number };
    },
  });
  return cursorPosition;
}
