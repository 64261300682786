import { Book, FIXED_PROTOCOL_TYPE_IDS, getHighestRoleName, isSystemUser, Protocol } from '@anschuetz-elog/common';
import jsPDF from 'jspdf';
import { uniq } from 'lodash';
import moment from 'moment';
import Vue from 'vue';

import { getDetails } from '#/components/protocol/ProtocolUIConfigDetails';
import useFeathers from '#/compositions/useFeathers';
import { MOMENT_FILE_TIMESTAMP } from '#/config/time';
import type { ProtocolsForPDFTablePerDay } from '#/export/pdf';
import { ApprovedPDFValue } from '#/export/pdf/types';
import i18n from '#/i18n';
import { getHistoryOfObject } from '#/lib/history';
import logger from '#/logger';
import { Line, ProtocolContentTypeDetails, ProtocolWithChanges } from '#/types';
import {
  dateStr,
  dateTimeStr,
  getChecklistTypeName,
  getProtocolTypeName,
  getUserFullName,
  getUserName,
  roundDateTime,
  timeStr,
} from '#/utilities';

import { insertAuditLogTable } from './auditLogTable';
import addFontsToJsPdf from './fonts/addFontsToJsPdf';
import { insertGeneralInformation } from './generalInformation';
import { generateFootersAndMarks } from './pageFooterAndMarks';
import { insertRecordsTable } from './recordsTable';
import signPdf from './signature';
import type {
  AuditLogDataForPDFTable,
  DateSubsection,
  GeneralInformationForAuditLogPDFTable,
  GeneralInformationForProtocolsPDFTable,
  ProtocolDataForPDFTable,
  ProtocolHistoryForPdfTable,
  RecordsForPDFTable,
} from './types';

type ProtocolVersion = {
  protocol: string;
  details: string | ProtocolContentTypeDetails[];
  predecessor: string | undefined;
  author: string;
  changeTimestamp: string;
  reasonForChange: string | undefined;
  isDeleted: boolean;
};

type ElementChanges = {
  changedField: string;
  changeFrom: string;
  changeTo: string;
};

const defaultOptions = {
  audit: false,
  addVoyageToGeneralInfo: false,
  exportNavDataSeparately: false,
  splitPdfExportByDays: false,
};

function appendDayToItems<T extends RecordsForPDFTable>(items: T[], day?: string): T[] {
  return items.map((item) => ({
    ...item,
    time: `${day || ''} ${item.time}`,
  }));
}

function combineItems(
  data: ProtocolDataForPDFTable | AuditLogDataForPDFTable,
  other: ProtocolDataForPDFTable | AuditLogDataForPDFTable,
  audit: boolean,
): ProtocolDataForPDFTable | AuditLogDataForPDFTable {
  if (audit) {
    return {
      ...data,
      auditLogs: [
        ...(data as AuditLogDataForPDFTable).auditLogs,
        ...(other as AuditLogDataForPDFTable).auditLogs.map((item) => ({
          ...item,
          eventTime: `${other.date} ${item.eventTime}`,
        })),
      ],
    };
  }
  const protocols = (data as ProtocolDataForPDFTable).protocols;
  const otherProtocols = (other as ProtocolDataForPDFTable).protocols;
  return {
    ...data,
    protocols: {
      navigation: [...protocols.navigation, ...appendDayToItems(otherProtocols.navigation, other.date)],
      others: [...protocols.others, ...appendDayToItems(otherProtocols.others, other.date)],
    },
  };
}

function getElementChanges(_predecessorDetails: Line[], _details: Line[]): ElementChanges[] {
  const detailsToReturn: ElementChanges[] = [];

  const flattenDetails = (details: Line[], _labelPrefix = ''): Line[] => {
    const labelPrefix = _labelPrefix ? `${_labelPrefix} ` : '';
    return details.flatMap((detail) => {
      if (Array.isArray(detail.value)) {
        return flattenDetails(detail.value, `${labelPrefix}${detail.label}`);
      }

      return {
        ...detail,
        label: `${labelPrefix}${detail.label}`,
      };
    });
  };

  const details = flattenDetails(_details);
  const predecessorDetails = flattenDetails(_predecessorDetails);

  for (const detail of details) {
    const predecessorDetail = predecessorDetails.find((predecessorDetail) => predecessorDetail.label === detail.label);

    if (!predecessorDetail) {
      detailsToReturn.push({
        changedField: detail.label,
        changeFrom: '',
        changeTo: detail.value as string,
      });
      continue;
    }

    if (predecessorDetail.value !== detail.value) {
      detailsToReturn.push({
        changedField: detail.label,
        changeFrom: predecessorDetail.value as string,
        changeTo: detail.value as string,
      });
    }
  }

  for (const predecessorDetail of predecessorDetails) {
    const detail = details.find((detail) => detail.label === predecessorDetail.label);

    // if there is a detail to compare we already checked it in the previous loop
    if (detail) {
      continue;
    }

    detailsToReturn.push({
      changedField: predecessorDetail.label,
      changeFrom: predecessorDetail.value as string,
      changeTo: '',
    });
  }

  return detailsToReturn;
}

function getProtocolChanges(
  predecessor: ProtocolVersion,
  protocolVersion: ProtocolVersion,
): {
  contentType: string;
  details: ElementChanges[];
}[] {
  if (protocolVersion.isDeleted) {
    return [{ contentType: 'deleted', details: [] }];
  }

  if (typeof protocolVersion.details === 'string' && typeof predecessor.details === 'string') {
    const predecessorValue = predecessor.details.trim();
    const successorValue = protocolVersion.details.trim();
    const valueChange =
      predecessorValue !== successorValue
        ? [
            {
              changedField: 'value',
              changeFrom: predecessorValue,
              changeTo: successorValue,
            },
          ]
        : [];

    return [
      {
        contentType: '',
        details: valueChange,
      },
    ];
  }

  const result: {
    contentType: string;
    details: ElementChanges[];
  }[] = [];

  const protocolVersionDetails = protocolVersion.details as ProtocolContentTypeDetails[];
  const predecessorDetails = predecessor.details as ProtocolContentTypeDetails[];

  protocolVersionDetails.forEach((protocolContent) => {
    const baseContentToCompare = predecessorDetails.find(
      (baseDetail) => baseDetail.contentType === protocolContent.contentType,
    );

    const elementChanges = getElementChanges(baseContentToCompare?.details || [], protocolContent.details);
    if (elementChanges.length === 0) {
      return;
    }

    result.push({
      contentType: protocolContent.contentType,
      details: elementChanges,
    });
  });

  predecessorDetails.forEach((content) => {
    const successorContentToCompare = protocolVersionDetails.find(
      (baseDetail) => baseDetail.contentType === content.contentType,
    );

    // if there is a content to compare we already checked it in the previous loop
    if (successorContentToCompare) {
      return;
    }

    const elementChanges = getElementChanges(content.details, []);
    if (elementChanges.length === 0) {
      return;
    }

    result.push({
      contentType: content.contentType,
      details: elementChanges,
    });
  });

  if (result.length === 0) {
    // add an empty difference to show amendment in history table
    result.push({
      contentType: '',
      details: [],
    });
  }

  return result;
}

// returns the information about who changed which field, at what time, and why between consecutive
// changes for a record in a chronological order
export function getProtocolVersionDifferences(protocolVersions: ProtocolVersion[]): ProtocolHistoryForPdfTable[] {
  // skip if there is only the initial version of a record
  if (protocolVersions.length < 2) {
    return [];
  }

  // placeholder to fill with changes later on
  const changes: ProtocolHistoryForPdfTable[] = [];

  // get the initial version of a record. (will always have predecessor undefined)
  let previousVersion = protocolVersions.find((protocol) => protocol.predecessor === undefined);
  if (!previousVersion) {
    return [];
  }

  changes.push({
    changedBy: previousVersion.author,
    timestamp: previousVersion.changeTimestamp,
    reasonForChange: '',
    changes: [{ contentType: 'initial', details: [] }], // TODO: translate
  });

  let protocolVersion = protocolVersions.find((protocol) => protocol.predecessor === previousVersion?.protocol);
  if (!protocolVersion) {
    return changes;
  }

  // fill changes array here
  while (protocolVersion) {
    changes.push({
      changedBy: protocolVersion.author,
      timestamp: protocolVersion.changeTimestamp,
      reasonForChange: protocolVersion.reasonForChange || '',
      changes: getProtocolChanges(previousVersion, protocolVersion),
    });

    previousVersion = protocolVersion;
    protocolVersion = protocolVersions.find((protocol) => protocol.predecessor === previousVersion?.protocol);
  }

  changes.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));

  return changes;
}

export function getProtocolsForPdfExport(
  book: Book | undefined,
  allProtocols: Protocol[],
  protocolsForExport: ProtocolWithChanges[],
): ProtocolDataForPDFTable[] {
  const uniqueDates = uniq(
    protocolsForExport.map((protocol) => roundDateTime(protocol.timestamp).startOf('day').valueOf()),
  );
  const datesSorted = uniqueDates.sort((a, b) => b - a).map((date) => moment(date).toISOString());
  return datesSorted.map((date) => {
    return {
      date: dateStr(date),
      protocols: restructureProtocolsForEachDayForPdfExport(book, allProtocols, protocolsForExport, moment(date)),
    };
  });
}

function restructureProtocolsForEachDayForPdfExport(
  book: Book | undefined,
  allProtocols: Protocol[],
  protocols: ProtocolWithChanges[],
  date: moment.Moment,
): ProtocolsForPDFTablePerDay {
  const protocolsPreparedPerDay: ProtocolsForPDFTablePerDay = { navigation: [], others: [] };
  const protocolsForSelectedDay = protocols.filter(
    (protocol) => roundDateTime(protocol.timestamp).startOf('day').format('X') === date.format('X'),
  );
  for (const protocol of protocolsForSelectedDay) {
    if (
      book?.displayNavigationRecordsSeparatlyInExports &&
      ('_id' in protocol.type ? protocol.type._id : protocol.type.ref._id) === FIXED_PROTOCOL_TYPE_IDS.NAVIGATION
    ) {
      protocolsPreparedPerDay.navigation.push(
        restructuredForPdfExport(allProtocols, protocol, getProtocolTypeName(protocol)),
      );
    } else {
      protocolsPreparedPerDay.others.push(
        restructuredForPdfExport(allProtocols, protocol, getProtocolTypeName(protocol)),
      );
    }
  }
  return protocolsPreparedPerDay;
}

function formatApprovalForPdf(approved: boolean): ApprovedPDFValue {
  if (approved) {
    return 'y';
  }
  return 'n';
}

function restructuredForPdfExport(
  protocols: Protocol[],
  protocol: Protocol,
  protocolTypeName: string,
): RecordsForPDFTable {
  /** map protocol history such that it will include all relevant information to print in PDF */
  const feathers = useFeathers();
  const protocolHistory = getHistoryOfObject(protocols, protocol)
    .reverse()
    .map((protocol) => {
      let details = getDetails(protocol, true);
      const booksStr = protocol.books
        .map(({ _id }) => feathers.get('localCache').getBookById(_id).name)
        .sort()
        .join(', ');

      // insert changes made to "Time of event (SMT)" and "shown in books" into details for protocol history
      if (!Array.isArray(details)) {
        details = [
          {
            contentType: 'Details',
            details: [{ label: 'Details', value: details }],
          },
        ];
      }
      details.unshift({
        contentType: 'Type',
        details: [
          {
            label: 'Type',
            value: '_id' in protocol.type ? getProtocolTypeName(protocol) : getChecklistTypeName(protocol),
          },
        ],
      });
      details.push(
        ...[
          {
            contentType: 'Time of event',
            details: [{ label: 'Time of event (SMT)', value: dateTimeStr(protocol.timestamp) }],
          },
          { contentType: 'Shown in books', details: [{ label: 'Shown in books', value: booksStr }] },
        ],
      );
      const createdTimestamp = moment(protocol.createdTimestamp).utc().format();
      return {
        protocol: protocol._id,
        details: details,
        predecessor: protocol.predecessor?._id,
        author: getUserName(protocol.author),
        changeTimestamp: dateTimeStr(createdTimestamp),
        reasonForChange: protocol.reasonForChange || '',
        isDeleted: protocol.deleted || false,
      };
    });

  const history = getProtocolVersionDifferences(protocolHistory);

  const authorInfo = [getUserName(protocol.author)];
  const user = feathers.get('localCache').getUserById(protocol.author._id);
  if (!isSystemUser(user)) {
    authorInfo.push(getHighestRoleName(user.roles));
  }
  const protocolAuthor = `${getUserFullName(protocol.author)} (${authorInfo.join(' | ')})`;

  return {
    time: timeStr(protocol.timestamp),
    type: protocolTypeName,
    details: getDetails(protocol),
    author: protocolAuthor,
    secondApproval: formatApprovalForPdf(protocol.secondApproved),
    firstApproval: formatApprovalForPdf(protocol.firstApproved),
    deleted: protocol.deleted || false,
    history,
  };
}

export async function pdfExport(
  data: (ProtocolDataForPDFTable | AuditLogDataForPDFTable)[],
  generalInfo: GeneralInformationForProtocolsPDFTable | GeneralInformationForAuditLogPDFTable,
  options: Partial<typeof defaultOptions> = {},
): Promise<void> {
  try {
    await pdfExportInternal(data, generalInfo, options);
  } catch (error) {
    Vue.toasted.error(i18n.tc('export.pdf_error'), { duration: 5000 });
    logger.error('Error in pdf export', error);
  }
}

async function pdfExportInternal(
  data: (ProtocolDataForPDFTable | AuditLogDataForPDFTable)[],
  generalInfo: GeneralInformationForProtocolsPDFTable | GeneralInformationForAuditLogPDFTable,
  options: Partial<typeof defaultOptions> = {},
): Promise<void> {
  const opt = { ...defaultOptions, ...options };
  const undefinedText = ' - not defined -';
  let pdfDocument = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
  pdfDocument = addFontsToJsPdf(pdfDocument);
  const currentDateTime = moment();
  const dateSubsections: DateSubsection[] = [];

  if (!opt.splitPdfExportByDays) {
    const base: ProtocolDataForPDFTable | AuditLogDataForPDFTable = opt.audit
      ? {
          auditLogs: [],
        }
      : {
          protocols: {
            navigation: [],
            others: [],
          },
        };
    data = [
      data.reduce<ProtocolDataForPDFTable | AuditLogDataForPDFTable>(
        (allData, dataOfDay) => combineItems(allData, dataOfDay, opt.audit),
        base,
      ),
    ];
  }

  data.forEach((d, index) => {
    if (index > 0) {
      pdfDocument.addPage();
    }
    let cursorPosition = insertGeneralInformation(
      pdfDocument,
      d.date,
      generalInfo,
      opt.addVoyageToGeneralInfo,
      currentDateTime,
      undefinedText,
      opt.audit,
    );
    if (opt.audit) {
      insertAuditLogTable(pdfDocument, d as AuditLogDataForPDFTable, cursorPosition, opt.splitPdfExportByDays);
    } else {
      if (opt.exportNavDataSeparately) {
        cursorPosition = insertRecordsTable(
          pdfDocument,
          (d as ProtocolDataForPDFTable).protocols.navigation,
          cursorPosition,
          'Navigation Records',
          opt.splitPdfExportByDays,
        );
      }
      insertRecordsTable(
        pdfDocument,
        (d as ProtocolDataForPDFTable).protocols.others,
        cursorPosition,
        opt.exportNavDataSeparately ? 'Other Records' : 'Records',
        opt.splitPdfExportByDays,
      );
    }
    const automaticPageCounter = pdfDocument.getCurrentPageInfo().pageNumber;
    dateSubsections.push({
      endPage: automaticPageCounter,
      noOfPages:
        automaticPageCounter - (dateSubsections.length ? dateSubsections[dateSubsections.length - 1].endPage : 0),
      date: d.date,
    });
  });

  generateFootersAndMarks(
    pdfDocument,
    opt.audit ? undefined : (generalInfo as GeneralInformationForProtocolsPDFTable).vesselName,
    generalInfo.signingUser,
    opt.splitPdfExportByDays ? dateSubsections : undefined,
    generalInfo.gatewayVersion,
  );
  if (opt.audit) {
    pdfDocument.save(`auditLog_${currentDateTime.format(MOMENT_FILE_TIMESTAMP)}.pdf`);
    return;
  }
  const filename = `${(generalInfo as GeneralInformationForProtocolsPDFTable).bookName
    .toLowerCase()
    .replace(' ', '')}_${currentDateTime.format(MOMENT_FILE_TIMESTAMP)}.pdf`;

  try {
    const signedPdfBuffer = await signPdf(pdfDocument);
    const blob = new Blob([signedPdfBuffer], {
      type: 'application/pdf',
    });
    saveAs(blob, filename);
  } catch (error) {
    Vue.toasted.error(i18n.tc('export.pdf_signing_error'), {
      duration: 5000,
      action: {
        text: i18n.tc('export.download_without_signature'),
        onClick: () => {
          pdfDocument.save(filename);
        },
      },
    });
  }
}
