import { Application, createApplication } from '@anschuetz-elog/common';
import { AdapterService } from '@feathersjs/adapter-commons';
import auth, { AuthenticationClientOptions } from '@feathersjs/authentication-client';
import { Application as FeathersApplication, Id, ServiceMethods } from '@feathersjs/feathers';

import logger from '#/logger';
import store from '#/store';
import { LS_AUTH_TOKEN } from '#/store/modules/auth';

import ELogAuthenticationClient from './AuthenticationClient';

export type ClientApplication = Application;

export function createFeathersClient(): Application {
  const feathersClient = createApplication();
  feathersClient.setMaxListeners(30);

  feathersClient.on('login', () => {
    logger.info('feathers: logged in');
    store.commit.websocket.setAuthenticated(true);
  });

  feathersClient.on('logout', () => {
    logger.info('feathers: logged out bye bye');
    store.commit.websocket.setAuthenticated(false);
  });

  feathersClient.on('disconnect', () => {
    logger.info('feathers: disconnect');
  });

  feathersClient.hooks({
    error({ error }) {
      logger.error('feathers: received error ', error);
    },
  });

  const authOptions: Partial<AuthenticationClientOptions> = {
    storageKey: LS_AUTH_TOKEN,
  };
  if (import.meta.env.VITE_APP_MODE !== 'cloud') {
    authOptions.Authentication = ELogAuthenticationClient;
  }

  feathersClient.configure(auth(authOptions));

  return feathersClient;
}

let app: ClientApplication | undefined;

function init(): ClientApplication {
  app = createFeathersClient() as unknown as Application;

  return app;
}

export default function useFeathers(): ClientApplication {
  if (!app) {
    return init();
  }

  return app;
}

export type PotentialIds = {
  id?: Id;
  _id?: Id;
};

export function getId(item: PotentialIds): Id {
  if (item.id) {
    return item.id;
  }
  if (item._id) {
    return item._id;
  }
  throw new Error('Unable to retrieve id from item');
}

export type ServiceTypes = Application extends FeathersApplication<infer S> ? S : never;

// TODO: the checks below are necessary due to the prerelease state of feathers v5. The problem there is
// that the AdapterService interface is not yet updated and is not compatible with the ServiceMethods interface
// and therefor needs to be checked separately.
export type ServiceModel<T extends keyof ServiceTypes> = ServiceTypes[T] extends AdapterService<infer M1>
  ? M1
  : ServiceTypes[T] extends ServiceMethods<infer M2>
    ? M2
    : never;
