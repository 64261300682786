import { AbilityBuilder, defineAbility } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VMdDateRangePicker from 'v-md-date-range-picker';
import Vue, { PluginFunction } from 'vue';
import { Fragment } from 'vue-frag';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import VuejsDialog from 'vuejs-dialog';
import Vuetify from 'vuetify';

import i18n from '#/i18n';
import { observeUserActivity } from '#/lib';
import { routes } from '#/router';

import FormInputElement from './components/form/FormInputElement.vue';
import vuetify from './plugins/vuetify';

type PluginOptions = {
  router: VueRouter;
  parentRouteName?: string;
};

const install: PluginFunction<PluginOptions> = (vue: typeof Vue, options?: PluginOptions): void => {
  if (options === undefined) {
    throw new Error('Plugin options can not be undefined');
  }
  const { router, parentRouteName } = options;

  if (parentRouteName) {
    const parentRoute = router.options.routes?.find((route) => route.name === parentRouteName);
    if (!parentRoute) {
      throw new Error(`Unable to find parent route with name "${parentRouteName}"`);
    }
    parentRoute.children = [
      ...(parentRoute.children || []),
      ...routes.map((route) => {
        if (route.path.startsWith('/')) {
          return { ...route, path: route.path.substr(1) };
        }
        return route;
      }),
    ];
    router.addRoute(parentRoute);
  } else {
    router.addRoutes(routes);
  }

  vue.use(Toasted, { theme: 'custom', position: 'bottom-center', duration: 3000, router });
  vue.use(Vuetify);
  vue.use(VuejsDialog);
  vue.use(VMdDateRangePicker);
  Vue.use(PiniaVuePlugin);

  Vue.component('Fragment', Fragment);
  // TODO: Remove if vue-tsc supports recursive component imports
  Vue.component('FormInputElement', FormInputElement);

  observeUserActivity();

  vue.use(
    abilitiesPlugin,
    defineAbility((can: AbilityBuilder['can'], cannot: AbilityBuilder['cannot']) => {
      cannot('manage', 'all'); // TODO: find better default ability
    }),
  );

  const pinia = createPinia();

  Object.assign(options, {
    vuetify,
    i18n,
    pinia,
  });
};

export default install;
